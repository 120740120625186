.logoDesktop {
    display: none;
}

@media screen and (min-width:960px) {

    .logoDesktop {
        display: block !important;
        width: 5em;
        margin-bottom: 1.2em;
    }

    .foot_cardPedido .valor_atributoCardPedido {
        max-width: 10ch !important;
    }

}

@media screen and (min-width:1245px) {

    .container_InformacoesPedido {
        height: 100% !important;
        align-items: stretch;
        row-gap: 1em;
        grid-template-columns: 30.5% 67.7% !important;
    }
    
    .secao_resumoPedido {
        font-size: 0.82em !important;
    }

    .informacoes_ResumoPedido {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1em;
    }

    .informacoes_ResumoPedido .linha_resumoPedido:nth-child(4) {
        grid-row: 1;
        grid-column: 2;
    }

    .secao_resumoPedido .left_LinhaResumoPedido {
        width: 60% !important;
    }

}

@media screen and (min-width:1300px) {

}

@media screen and (min-width:1400px) {

    .foot_cardPedido .valor_atributoCardPedido {
        max-width: 16ch !important;
    }

}

@media screen and (min-width:1800px) {

    .foot_cardPedido .valor_atributoCardPedido {
        max-width: 23ch !important;
    }

}

@media screen and (min-width:1900px) {

    .foot_cardPedido .valor_atributoCardPedido {
        max-width: 28ch !important;
    }

}

@media screen and (min-height:860px) and (min-width:1245px) {

    .secao_botoesAcaoPedido {
        align-self: flex-end !important;
    }

}