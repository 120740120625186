.container_InformacoesPedido {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    row-gap: 10px;
}

.secao_headerPedido {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 0.5em;
}

.secao_headerPedido img {
    max-height: 12.5vh;
}

.linha_headerPedido {
    color: #303030;
    max-width: 100vw;

    text-overflow: ellipsis;
    overflow: hidden;
}

.desc_LinhaHeaderPedido {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1em;
    margin-right: 0.5em;
}

.valor_LinhaHeaderPedido {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.cabecalho_secaoItensPedido {
    font-weight: 600;
    color: #303030;
    margin-bottom: .5em;
}

.linha_resumoPedido {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    background-color: white;
    padding: 0.5em;
    border-radius: 0.3em;
}

.left_LinhaResumoPedido {
    display: flex;
    align-items: center;
}

.container_iconeResumoPedido {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #f4f8ff;
    padding: 0.6em;
    margin-right: 0.5em;
    color: #3C7EF7;
    border-radius: 2em;
}

.desc_linhaResumoPedido {
    color: #303030;
    font-weight: 400;

    max-width: 36vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.informacoes_ResumoPedido {
    margin-top: 0.5em;
    row-gap: 0.5em;
}

.valor_linhaResumoPedido {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1.1em;
    color: #3C7EF7;
}

.secao_botoesAcaoPedido {
    display: grid;
    row-gap: 0.8em;
    width: 100%;
}

.secao_botoesAcaoPedido svg {
    margin-left: 1em;
    width: 1.1em;
    height: 1.1em;
}