.info_TopoPedidoSelecionado{
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
}

.desc_TopoPedidoSelecionado{
    font-size: 0.92em;
    color: #303030;
    font-weight: 400;
}

.numPedido_DescTopoPedidoSelecionado{
    font-weight: 700;
    margin-left: 0.3em;
}